import React, { Suspense } from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min";
import { BrowserRouter } from 'react-router-dom';
import ScrollToTop from './componants/ScrollToTop/ScrollToTop';

const App = React.lazy(()=>import("./App"));
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <ScrollToTop />
      <React.Suspense fallback={<></>}>
        <App />
      </React.Suspense>
    </BrowserRouter>
  </React.StrictMode>
);
